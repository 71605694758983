
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Open Sans";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: #0e5597;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: white;
  font-weight: 900;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  color: white;
  background-color: #0e5597;
  border-radius: 8px;
  padding: 5px 10px 5px 10px;
}
.navbar a:hover {
  font-weight: 600;
}
.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

/* COCROR */
.cocror {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.cocror label {
  text-align: left;
  font-weight: 500;
  display: block;
}
.cocror h2 {
  margin-bottom: 30px;
}
.cocror h3 {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}
.cocror input, .cocror textarea, .cocror select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.cocror textarea {
  resize: none;
}
.cocror button {
  background: #0e5597;
  color: #fff;
  border: 0;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}

.results {
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 8px;
}

.results p {
  text-align: left;
  font-weight: 500;
}